import IndexPage from "@/pages/IndexPage";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "IndexPage",
    components: { default: IndexPage },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
