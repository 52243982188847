<template>
  <div class="bg-primary text-primary min-vh-100 d-flex">
    <img class="my-auto mx-auto w-70 w-md-40 h-auto rounded-circle" src="@/assets/img/bg-logo.png" alt=""/>
  </div>
  <div class="bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-6 py-6 py-mb-4">
          <VueAgile
            class="main_agile"
            ref="main_agile"
            :options="agile_options"
            :as-nav-for="asNavFor1"
          >
            <div
              class="slide"
              v-for="(slide, index) in slides"
              :key="index"
              :class="`slide--${index}`"
            >
              <img :src="slide" :alt="slide" class="border-radius-lg"/>
            </div>
          </VueAgile>
          <VueAgile class="thumbnails mt-2" ref="thumbnails" :options="thumbnails_options" :as-nav-for="asNavFor2">
            <div class="slide slide--thumbniail" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`"
                 @click="$refs.thumbnails.goTo(index)">
              <img :src="slide" class="border-radius-lg" :alt="slide"/>
            </div>
            <template v-slot:prevButton>
              <i class="fas fa-chevron-left text-dark"></i>
            </template>
            <template v-slot:nextButton>
              <i class="fas fa-chevron-right text-dark"></i>
            </template>
          </VueAgile>
        </div>
        <div class="col-md-6 my-auto pb-4">
          <div class="text-primary font-condensed font-weight-light h3">
            Если вы искали мастера, который сможет сделать крутую татуировку в Саратове, то вы оказались в нужном месте.
            Мы работаем в разных стилях, и сами рисуем для вас дизайны. У нас много наработок, чтобы ваша татуировка
            стала по-настоящему уникальной

            <div class="mt-5 text-center">
              <h3 class="h3 text-primary text-uppercase font-condensed">Ждем вас в наших соц сетях</h3>
              <a
                class="btn bg-primary mt-3 me-3 border-radius-md avatar avatar-md text-light"
                href="https://vk.com/cvetato"
                target="_blank"
              >
                <i class="fab fa-vk"></i>
              </a>
              <a
                class="btn bg-primary mt-3 me-3 border-radius-md avatar avatar-md text-light"
                href="https://t.me/cvetato"
                target="_blank"
              >
                <i class="fab fa-telegram-plane"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-primary">
    <div class="container pb-4">
      <h2 class="h3 text-center font-condensed text-light py-4 text-uppercase">Как нас найти</h2>
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad7aa3a870959506f45b7d754abb6feccee7aae340eef0388ccdec3debc859112&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
    </div>

  </div>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "IndexPage",
  components: {
    VueAgile,
  },
  data() {
    return {
      title: "Welcome to Bang Bang",
      services: [],
      asNavFor1: [],
      asNavFor2: [],
      agile_options: {
        dots: false,
        fade: true,
        navButtons: false,
      },
      thumbnails_options: {
        autoplay: true,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        changeDelay: 10000,
        speed: 2000,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
            },
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
      slides: [
        require("@/assets/img/album/1.jpg"),
        require("@/assets/img/album/2.jpg"),
        require("@/assets/img/album/3.jpg"),
        require("@/assets/img/album/4.jpg"),
        require("@/assets/img/album/5.jpg"),
        require("@/assets/img/album/6.jpg"),
        require("@/assets/img/album/7.jpg"),
        require("@/assets/img/album/8.jpg"),
        require("@/assets/img/album/9.jpg"),
        require("@/assets/img/album/10.jpg"),
        require("@/assets/img/album/11.jpg"),
        require("@/assets/img/album/12.jpg"),
        require("@/assets/img/album/13.jpg"),
        require("@/assets/img/album/14.jpg"),
        require("@/assets/img/album/15.jpg"),
        require("@/assets/img/album/16.jpg"),
        require("@/assets/img/album/17.jpg"),
        require("@/assets/img/album/18.jpg"),
        require("@/assets/img/album/19.jpg"),
        require("@/assets/img/album/20.jpg"),
      ],
    };
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main_agile);
  },
};
</script>

<style scoped></style>
