<template>
  <!-- Navbar Dark -->
  <nav
    class="navbar position-fixed position-static w-100 navbar-dark bg-light z-index-3 py-1">
    <div class="container">
      <div class="text-center w-100 w-md-auto d-flex">
        <a class="navbar-brand text-light d-flex mx-auto" href="/">
          <img src="@/assets/img/min-bg-logo.png" class="me-md-3 mh-50px border-radius-md my-auto me-2"/>
          <h1 class="font-weight-bold h3 text-primary text-uppercase font-condensed my-auto pt-2">
            Тату мастерская
          </h1>
        </a>
      </div>
      <div class="col-auto text-center px-3 py-0 py-md-2">
      </div>

      <div class="text-center w-100 w-md-auto d-none d-md-block" id="navigation">
        <div class="navbar-nav navbar-nav-hover ms-auto text-primary h4 d-block">
          <a
            class="btn bg-primary mt-3 me-3 border-radius-md avatar avatar-md"
            href="https://vk.com/cvetato"
            target="_blank"
          >
            <i class="fab fa-vk"></i>
          </a>
          <a
            class="btn bg-primary mt-3 me-3 border-radius-md avatar avatar-md"
            href="https://t.me/cvetato"
            target="_blank"
          >
            <i class="fab fa-telegram-plane"></i>
          </a>
        </div>
      </div>
    </div>
  </nav>
  <nav
    class="navbar position-fixed bottom-0 w-100 navbar-dark bg-light z-index-3 py-1 d-block d-md-none">
    <div class="container d-flex">
      <a class="btn btn-primary p-2 mt-3 border-radius-md avatar avatar-md" href="/">
        <i class="fas fa-arrow-up"></i>
      </a>
      <a
        class="btn bg-primary mt-3 border-radius-md avatar avatar-md"
        href="https://vk.com/cvetato"
        target="_blank"
      >
        <i class="fab fa-vk"></i>
      </a>
      <a
        class="btn bg-primary mt-3 border-radius-md avatar avatar-md"
        href="https://t.me/cvetato"
        target="_blank"
      >
        <i class="fab fa-telegram-plane"></i>
      </a>
    </div>
  </nav>

  <!-- End Navbar -->
</template>

<script>
export default {
  name: "MainNavigation",
};
</script>

<style scoped>

</style>
