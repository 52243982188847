<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Цвет` : `Cvetato`
    }}</template>
    <template v-slot:og(title)="{ content, og }">
      {{ content }} - {{ og.description }}
    </template>
  </metainfo>
  <div>
    <div class="main-content bg-light">
      <MainNavigation />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { useActiveMeta, useMeta } from "vue-meta";
import MainNavigation from "@/components/MainNavigation";

export default {
  name: "App",
  components: {MainNavigation},
  setup() {
    useMeta({
      title: "",
      description: "",
      og: {
        title: "",
        description: "",
        image: [],
      },
      htmlAttrs: { lang: "ru", amp: true },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
};
</script>

<style></style>
